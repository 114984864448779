@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "Poppins";
    font-weight: 300;
    src: url("./fonts/Poppins-Light.ttf");
}
@font-face {
    font-family: "Poppins";
    font-weight: 400;
    font-weight: italic;
    src: url("./fonts/Poppins-Italic.ttf");
}
@font-face {
    font-family: "Poppins";
    font-weight: 400;
    src: url("./fonts/Poppins-Regular.ttf");
}
@font-face {
    font-family: "Poppins";
    font-weight: 500;
    src: url("./fonts/Poppins-Medium.ttf");
}
@font-face {
    font-family: "Poppins";
    font-weight: 600;
    src: url("./fonts/Poppins-SemiBold.ttf");
}
@font-face {
    font-family: "Poppins";
    font-weight: 700;
    src: url("./fonts/Poppins-Bold.ttf");
}
@font-face {
    font-family: "Poppins";
    font-weight: 800;
    src: url("./fonts/Poppins-ExtraBold.ttf");
}
@font-face {
    font-family: "Poppins";
    font-weight: 900;
    src: url("./fonts/Poppins-Black.ttf");
}

.flex-center {
    @apply flex justify-center items-center;
}

.legal ul {
    @apply list-disc list-inside ml-6 mt-4 space-y-2;
}
.legal ol {
    @apply list-decimal list-inside ml-6 mt-4 space-y-2;
}
.legal a {
    @apply text-nk break-all;
}
.legal h2 {
    @apply text-base md:text-lg font-semibold mt-3;
}
.legal h3 {
    @apply text-sm md:text-base font-semibold mt-2;
}
.legal pre {
    @apply text-sm md:text-base text-gray-200 ml-2;
}
.legal hr {
    @apply bg-gray-400;
}
.legal {
    @apply text-sm md:text-base;
}
.ReactModal__Overlay {
    background-color: rgba(0, 0, 0, 0.6);
}

@media (min-width: 768px) {
    /* .layout_full {
        width: 100%;
        transition: 0.5s;
    }
    .layout_login {
        @apply w-full md:w-5/12 lg:w-4/12;
        transition: 0.5s;
    } */
    .hero_hide {
        width: 0;
        transition: 0.5s;
    }
    .hero_show {
        width: 100%;
        transition: 0.5s;
    }

    .layout_shadow {
        /* box-shadow: -20px 0px 0px 0px rgba(255, 250, 220, 0.45);
        -webkit-box-shadow: -20px 0px 0px 0px rgba(255, 250, 220, 0.45);
        -moz-box-shadow: -20px 0px 0px 0px rgba(255, 250, 220, 0.45); */
        box-shadow: -20px 0px 0px 0px #260b0a24; /* rgb(49 49 49, 0.25) */
        -webkit-box-shadow: -20px 0px 0px 0px #260b0a24;
        -moz-box-shadow: -20px 0px 0px 0px #260b0a24;
    }
}

@keyframes leftright {
    0% {
        transform: translateX(-35%);
    }

    50% {
        transform: translateX(35%);
    }

    100% {
        transform: translateX(-35%);
    }
}
.swipeIcon {
    animation: leftright 2s ease infinite;
}

/* Scrollbar */
::-webkit-scrollbar {
    width: 13px;
    height: 13px;
}
::-webkit-scrollbar-thumb {
    background: #686868;
    border-radius: 9px;
}
::-webkit-scrollbar-thumb:hover {
    background: #969396;
}
::-webkit-scrollbar-track {
    background: #424242;
    border-radius: 9px;
    box-shadow: inset 0px 0px 0px 0px #f0f0f0;
}

.animate-bounce-x {
    animation: bounce-x 1s infinite;
}

@keyframes bounce-x {
    0%,
    100% {
        transform: translateX(-7.5%);
        animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
    50% {
        transform: translateX(0);
        animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
}
